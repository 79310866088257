@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
}

@font-face {
  font-family: "Gilroy-medium";
  src: local("medium"),
    url("./asset/fonts/Gilroy-Medium-2.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-regular";
  src: local("regular"),
    url("./asset/fonts/gilroy-regular-3.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-bold";
  src: local("bold"),
    url("./asset/fonts/gilroy-bold-4.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-black";
  src: local("black"),
    url("./asset/fonts/gilroy-black-6.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-itlic";
  src: local("black-italic"),
    url("./asset/fonts/gilroy-blackitalic-7.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-heavy-italic";
  src: local("heavy-italic"),
    url("./asset/fonts/Gilroy-HeavyItalic-8.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-extrabold-italic";
  src: local("extra-bold-italic"),
    url("./asset/fonts/Gilroy-ExtraBoldItalic-10.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-light";
  src: local("light"),
    url("./asset/fonts/Gilroy-Light-11.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-light-italic";
  src: local("light-italic"),
    url("./asset/fonts/Gilroy-LightItalic-12.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-thin";
  src: local("thin"),
    url("./asset/fonts/Gilroy-Thin-13.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-thin-italic";
  src: local("thin-italic"),
    url("./asset/fonts/Gilroy-ThinItalic-14.ttf") format("truetype");
}
